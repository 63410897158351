import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getURLs } from "../urlConfig";
import { Helmet } from "react-helmet-async";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SocialLogin from "../components/SocialLogin";
import {
  UserIcon,
  LockClosedIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import { AppDataContext } from "../context/appData";
import AuthImageWithHeading from "../components/AuthImageWithHeading";

const Signup = () => {
  // app info
  const {
    state: { appData },
  } = useContext(AppDataContext);

  // navigate state
  const navigate = useNavigate();
  // form data state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  // error state
  const [error, setError] = useState("");
  // social login error state
  const [socialLoginError, setSocialLoginError] = useState("");
  // signup loading state
  const [signupLoading, setSignupLoading] = useState(false);

  // redirect to login oage
  const handleLoginNavigation = () => {
    navigate("/login", { replace: true });
  };

  // handle input change
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData?.name) {
      return setError("Name field cannot be empty");
    }

    if (!formData?.email) {
      return setError("Email field cannot be empty");
    }

    if (!formData.password) {
      return setError("Password field cannot be empty");
    }
    setSignupLoading(true);

    axios
      .post(getURLs("verify-email"), { email: formData.email })
      .then((res) => {
        if (res.status === 200)
          navigate("/verify-otp", {
            state: {
              ...formData,
              isSigningup: true,
            },
          });
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 ||
          err?.response?.status === 401 ||
          err?.response?.status === 500
        )
          setError(err?.response?.data?.message);
        setSignupLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setError("");
  }, [formData]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Helmet>
        <title>AiTeacher - Register</title>
        <link rel="canonical" href="https://www.aiteacher.org/signup" />
      </Helmet>
      <div className="flex flex-col lg:flex-row items-center justify-center lg:items-start py-5 bg-[#f8f9fa] lg:bg-white dark:bg-darkBgColor1">
        <AuthImageWithHeading
          imageSrc={appData?.loginPageData?.sectionImage}
          heading={appData?.loginPageData?.heading}
        />

        <div className="lg:basis-1/2 w-full flex flex-col items-center relative mt-4 lg:mt-0">
          <form
            className="z-10 flex flex-col justify-center border dark:border-none bg-white dark:bg-purple2 shadow-shadow13 dark:shadow-none rounded-[30px] space-y-3 w-full max-w-md px-6 py-8"
            onSubmit={handleSubmit}
          >
            {/* social login buttons */}
            <SocialLogin setSocialLoginError={setSocialLoginError} />

            <div className="flex items-center justify-between space-x-4">
              <div className="w-1/4 border-b border-b-gray9" />
              <p className="font-urbanist font-normal opacity-70 dark:text-gray39 text-gray6 text-xs flex-grow-1 text-center">
                or Sign up with Email
              </p>
              <div className="w-1/4 border-b border-b-gray9" />
            </div>

            <div className="flex flex-col items-start">
              <p className="font-urbanist font-normal text-xs dark:text-white text-blue25">
                Name
              </p>
              {/*  email input */}
              <div className="flex items-center space-x-2 bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 mt-2 mb-5">
                <UserIcon className="h-4 w-4 dark:text-white" />
                <input
                  className="flex-grow outline-none border-none px-2 py-2 text-sm lg:text-base placeholder:text-xs font-urbanist font-normal text-gray-600 dark:text-white bg-transparent"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>

              <p className="font-urbanist font-normal text-xs dark:text-white text-blue25">
                Email Address
              </p>
              {/*  email input */}
              <div className="flex items-center space-x-2 bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 mt-2 mb-5">
                <EnvelopeIcon className="text- h-4 w-4 dark:text-white" />
                <input
                  className="flex-grow rounded-lg outline-none px-2 py-2 text-sm lg:text-base placeholder:text-xs font-urbanist font-normal text-gray-600 dark:text-white bg-transparent"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              <p className="font-urbanist font-normal text-xs dark:text-white text-blue25">
                Password
              </p>
              {/* password input */}
              <div className="flex items-center space-x-2 bg-white dark:bg-purple12 border dark:border-purple12 border-gray-200 rounded-lg w-full px-2 mt-2 mb-3">
                <LockClosedIcon className="h-4 w-4 dark:text-white" />
                <input
                  className="flex-grow rounded-lg outline-none px-2 py-2 text-sm lg:text-base placeholder:text-xs font-urbanist font-normal text-gray-600 dark:text-white bg-transparent"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex items-center space-x-2">
                <input type="checkbox" />
                <span className="font-urbanist font-normal text-xs dark:text-white text-blue26">
                  I agree to the{" "}
                  <span className="text-blue27">Terms & Conditions</span>
                </span>
              </div>

              {error?.length > 0 && (
                <span className="text-xs sm:text-sm font-urbanist text-red-500 font-medium mt-2 block">
                  {error}
                </span>
              )}

              {socialLoginError?.length > 0 && (
                <span className="text-xs sm:text-sm font-urbanist text-red-500 font-medium mt-2 block">
                  {socialLoginError}
                </span>
              )}
              <div className="w-full flex items-center justify-center mt-5">
                <button
                  className="w-full px-2 py-2 mt-4 max-w-xl font-urbanist font-bold text-white text-xs md:text-base xl:text-lg rounded-lg"
                  onClick={handleSubmit}
                  style={{
                    background: !signupLoading
                      ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                      : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
                  }}
                  disabled={signupLoading}
                >
                  Register
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between w-full">
              <p className="font-urbanist font-normal text-xs text-gray10">
                already have an account{" "}
                <span
                  className="text-blue27 underline underline-offset-2 cursor-pointer"
                  onClick={handleLoginNavigation}
                >
                  Login
                </span>{" "}
              </p>

              <span
                className="font-urbanist font-normal text-xs text-blue27 cursor-pointer"
                onClick={() => navigate("/forget-password")}
              >
                Forgot your password?
              </span>
            </div>
          </form>
          <div className="absolute top-24 bottom-0 bg-purple11 blur-[120px] w-full h-full dark:bg-neonPurpleColor dark:opacity-60" />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default Signup;
