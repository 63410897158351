import React, { useContext } from "react";
import SpecificationSection from "../components/SpecificationSection";
// import SubjectSection from "../components/SubjectSection";
import { Helmet } from "react-helmet-async";
import NewHero from "../components/NewHero";
import NewReviewsSection from "../components/NewReviewsSection";
import GlobeIcon from "../images/globe.png";
import PencilIcon from "../images/pencil.png";
import BulbIcon from "../images/bulb.png";
import GlobeWhiteIcon from "../images/globeWhite.svg";
import PencilWhiteIcon from "../images/pencilWhite.svg";
import BulbWhiteIcon from "../images/bulbWhite.svg";
import LearningExperienceSection from "../components/LearningExperienceSection";
import { DarkModeContext } from "../context/darkModeContext";

function Home() {
  const {
    state: { darkMode },
  } = useContext(DarkModeContext);
  return (
    <>
      {/* seo */}
      <Helmet>
        <title>AiTeacher</title>
        <meta
          name="description"
          content="An innovative online learning
            platform offering wide range of courses. Designed to make learning impactful."
        />
        <link rel="icon" href="/logo512.png" />
        <link rel="canonical" href="https://www.aiteacher.org/" />
        {/* Twitter meta tags below */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:image" content="" />
        <meta
          name="twitter:title"
          content="AiTeacher - An innovative online learning
          platform offering wide range of courses."
        />
        <meta
          name="twitter:description"
          content="An innovative online learning
          platform offering wide range of courses. Designed to make learning impactful."
        />

        {/* Facebook meta tags below */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aiteacher.org/" />
        <meta
          property="og:title"
          content="AiTeacher - An innovative online learning
          platform offering wide range of courses."
        />
        <meta
          property="og:description"
          content="An innovative online learning
          platform offering wide range of courses. Designed to make learning impactful."
        />
        <meta property="og:image" content="" />
      </Helmet>

      <div className="bg-white">
        <div className="relative bg-white dark:bg-darkBgColor1 rounded-bl-curveRadius rounded-br-curveRadius pt-2">
          {/* hero section */}
          <NewHero />

          <img
            src={darkMode ? GlobeWhiteIcon : GlobeIcon}
            alt="globe-icon"
            className="hidden md:block absolute top-44 right-20"
          />
          <img
            src={darkMode ? BulbWhiteIcon : BulbIcon}
            alt="globe-icon"
            className="hidden md:block absolute top-20 left-32"
          />
          <img
            src={darkMode ? PencilWhiteIcon : PencilIcon}
            alt="globe-icon"
            className=" hidden md:block absolute bottom-52 left-32"
          />

          <div className="absolute bottom-20 transform left-0 bg-neonColorBlue2 blur-[100px] right-1/2 h-[50%]" />
          <div className="absolute bottom-20 transform right-0 bg-neonPurpleColor blur-[100px] left-1/2 h-[50%]" />
        </div>
        {/* why choose us section */}
        <section className="relative pt-8 lg:pt-32 pb-8 lg:pb-32 dark:bg-darkBgColor1 bg-white z-10 px-4 md:px-10">
          <SpecificationSection />
        </section>

        <section className="pt-8 lg:pt-16 pb-8 lg:pb-16 dark:bg-darkBgColor1 bg-white">
          <LearningExperienceSection />
        </section>

        {/* review section */}
        <section className="">
          <NewReviewsSection />
        </section>
      </div>
    </>
  );
}

export default Home;
