import React, { useContext, useRef, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { getURLs } from "../urlConfig";
import ReCAPTCHA from "react-google-recaptcha";
import { AppDataContext } from "../context/appData";
import SocialMediaIcons from "../components/SocialMediaIcons";

const Contact = () => {
  const {
    state: { appData },
  } = useContext(AppDataContext);

  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    name: "",
    message: "",
  });

  const [isSendingMail, setIsSendingMail] = useState(false);
  const [mailSendError, setMailSendError] = useState("");
  const [mailSend, setMailSend] = useState(false);

  // ref for captcha
  const captchaRef = useRef(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    setIsSendingMail(true);

    if (
      !formData.name ||
      !formData.email ||
      !formData.subject ||
      !formData.message
    ) {
      return;
    }

    axios
      .post(
        getURLs("contact-form"),
        {
          name: formData.name,
          email: formData.email,
          subject: formData.subject,
          message: formData.message,
          captchaRes: token,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setIsSendingMail(false);
          setMailSend(true);
          setFormData({ email: "", subject: "", message: "", name: "" });
          captchaRef.current.reset();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsSendingMail(false);
        setMailSend(false);
        if (err?.response?.data?.message) {
          setMailSendError(err?.response?.data?.message);
        } else {
          setMailSendError(err?.message);
        }
        captchaRef.current.reset();
      });
  };

  return (
    <div className="flex flex-col w-full lg:px-24 md:px-16 px-4 py-10 dark:bg-darkBgColor1 bg-white">
      {mailSend ? (
        <span className="flex items-start text-green-500 text-base sm:text-lg md:text-xl font-inter font-medium ">
          <CheckCircleIcon className="h-5 w-5 md:h-6 md:w-6 ml-2 mt-[2px]" />
          {appData?.contactPageData?.successMessage}
        </span>
      ) : (
        <div className="flex flex-col items-center w-full">
          <h1 className="font-bold font-urbanist dark:text-white text-black text-xl lg:text-2xl block mb-4 ">
            {appData?.contactPageData?.heading}
          </h1>
          <p className="font-urbanist text-center max-w-2xl font-normal text-sm dark:text-white text-gray28">
            {appData?.contactPageData?.description}
          </p>

          <div className="w-full flex flex-col-reverse items-center md:flex-row space-x-0 space-y-4 md:space-y-0 md:space-x-10 my-12">
            {/* form */}
            <form
              className="flex flex-col space-y-4 w-full md:basis-1/2 relative"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col sm:flex-row items-center space-x-0 space-y-4 sm:space-y-0 sm:space-x-4">
                <div className="flex flex-col space-y-2 basis-1/2 w-full">
                  <p className="font-urbanist font-semibold text-xs dark:text-white text-black">
                    Name
                  </p>
                  {/*  email input */}
                  <input
                    className="w-full flex-grow outline-none border-none px-4 py-3 text-sm lg:text-base placeholder:text-xs font-inter font-normal text-gray-600 dark:bg-purple2 bg-gray35 rounded-full z-10"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="John Doe"
                  />
                </div>

                <div className="flex flex-col space-y-2 basis-1/2 w-full">
                  <p className="font-urbanist font-semibold text-xs dark:text-white text-black">
                    Email Address
                  </p>
                  {/*  email input */}
                  <input
                    className="w-full flex-grow outline-none px-4 py-3 text-sm lg:text-base placeholder:text-xs font-inter font-normal text-gray-600 dark:bg-purple2 bg-gray35 rounded-full z-10"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="example@mail.com"
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <p className="font-urbanist font-semibold text-xs dark:text-white text-black">
                  Subject
                </p>
                {/*  email input */}
                <input
                  className="flex-grow outline-none px-4 py-3 text-sm lg:text-base placeholder:text-xs font-inter font-normal text-gray-600 dark:bg-purple2 bg-gray35 rounded-[50px] z-10"
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="About AiTeacher"
                />
              </div>

              <div className="flex flex-col space-y-2">
                <p className="font-urbanist font-semibold text-xs dark:text-white text-black">
                  Leave us message
                </p>
                {/* password input */}
                <textarea
                  className="flex-grow outline-none px-4 py-3 text-sm lg:text-base placeholder:text-xs font-inter font-normal text-gray-600 dark:bg-purple2 bg-gray35 rounded-lg z-10"
                  type="text"
                  name="message"
                  rows={5}
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Please type your message here..."
                />
              </div>

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                className="z-10"
              />

              {mailSendError?.length > 0 && (
                <span className="text-xs sm:text-sm font-inter text-red-500 font-medium mt-2 block z-10">
                  {mailSendError}
                </span>
              )}

              <div className="flex flex-col sm:flex-row items-center sm:justify-between space-y-6 sm:space-y-0 z-10">
                <button
                  className="px-10 py-5 font-inter font-bold text-white text-xs rounded-[40px]"
                  onClick={handleSubmit}
                  style={{
                    background: !isSendingMail
                      ? "linear-gradient(90deg, rgba(79, 172, 254, 0.6) 0%, rgba(0, 242, 254, 0.6) 100%), #2898FF"
                      : "linear-gradient(90deg, rgba(79, 172, 254, 0.5) 0%, rgba(0, 242, 254, 0.5) 100%)",
                  }}
                  disabled={isSendingMail}
                >
                  Submit
                </button>

                <SocialMediaIcons />
              </div>
              <div className="hidden dark:lg:block absolute bottom-20 transform left-0 bg-neonColorBlue2 blur-[120px] right-0 h-[80%] opacity-60" />
            </form>

            {/* img */}
            <div className="w-full lg:basis-1/2 flex flex-col items-center px-5 sm:px-2">
              <img
                src={appData?.contactPageData?.imgLight}
                alt="contact"
                className="max-w-sm lg:max-w-xl max-h-[350px] w-full h-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
